// Entry point for the build script in your package.json
// import "@hotwired/turbo-rails"
import "./controllers"
import * as bootstrap from "bootstrap"
import "./jquery.nice-select.min"
import "./jquery-ui.min"
import "./jquery.slicknav"
import "./mixitup.min"
import "./owl.carousel.min"
import "./main"
import "trix"
import "@rails/actiontext"

// function toggleSearch(){
//   const searchButton = document.querySelector(".js-search")
//   const search  =document.querySelector(".js-search-form")
//   if(searchButton && search){
//     searchButton.onclick = ()=>{
//       getComputedStyle(search).display === "none" ? search.style.display = 'flex' : search.style.display = "none"
//     }
//   }
// }
// function calcWidth(){
//   const slider = document.querySelector(".slider")
//   const sliderWidth = slider.clientWidth;
//   const slideList = slider.querySelectorAll('.slide')
//   slideList.forEach(item=>{
//     item.style.width = sliderWidth + 'px';
//   })
// }

// function menu(){
//   const menuButton = document.querySelector(".menu__icon")
//   const menuMobile = document.querySelector(".js-menu")
//   const menuClose = document.querySelector('.nav__icon')
//   menuClose.onclick=()=>{
//     menuMobile.classList.toggle('hide')
//   }
//   menuButton.onclick = ()=>{
//     menuMobile.classList.toggle('hide')
//   }
// }



// function backCall(){
//   const modal = document.querySelector("#modal")
//   const form  = modal.querySelector(".js-form-call")
//   if (form){
//     form.onsubmit = (e)=>{
//       // e.preventDefault()
//       // const formData = new FormData(form);
//       // console.log(formData.get('contact'))
//       // console.log(formData.get('phone'))
//       // console.log(formData.get('email'))
//       modal.classList.add('hide')
//     }
//   }
// }

// function orderSend(){
//   const modal = document.querySelector("#modal-order")
//   const form  = modal.querySelector(".js-form-order")
//   if (form){
//     form.onsubmit = (e)=>{
//       // e.preventDefault()
//       // const formData = new FormData(form);
//       // console.log(formData.get('contact'))
//       // console.log(formData.get('phone'))
//       // console.log(formData.get('email'))
//       // console.log(formData.get('order'))
//       // console.log(formData.get('article'))
//       modal.classList.add('hide')
//     }
//   }
// }

// function modalToggle(){
//   const modal = document.querySelector("#modal")
//   const close = document.querySelector(".js-modal-close")
//   const phoneIcon = document.querySelector(".js-phone")
//   phoneIcon.onclick = ()=>{
//     modal.classList.toggle('hide')
//   }
//   close.onclick = ()=>{
//     modal.classList.add('hide')
//   }
// }

// function modalBannerToggle(){
//   const modal = document.querySelector("#modal")
//   const close = document.querySelector(".js-modal-close")
//   const bannerIcon = document.querySelector(".js-banner")
//   if (bannerIcon != null) {
//     bannerIcon.onclick = ()=>{
//       modal.classList.toggle('hide')
//     }
//     close.onclick = ()=>{
//       modal.classList.add('hide')
//     }
//   }
// }

// function modalOrderToggle(){
//   const modal = document.querySelector("#modal-order")
//   const close = modal.querySelector(".js-modal-close")
//   const chatIcon = document.querySelector(".js-chat")
//   chatIcon.onclick = ()=>{
//     modal.classList.toggle('hide')
//   }
//   close.onclick = ()=>{
//     modal.classList.add('hide')
//   }
// }

// try{
//   backCall()
//   orderSend()
//   modalOrderToggle()
//   modalToggle()
//   toggleSearch()
//   modalBannerToggle()
//   // calcWidth()
//   menu()
// }catch (e) {
//   console.log('error' + e)
// }


